/*
 * MODIFIED
 *    $Date: $
 */
/*
   https://codeburst.io/tutorial-make-a-bouncing-ball-entirely-with-css-1e7e3c853a50
   */
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 50px, 0);
            transform: translate3d(0, 50px, 0);
  }
}
@keyframes bounce {
  from {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 50px, 0);
            transform: translate3d(0, 50px, 0);
  }
}
.rolling {
  -webkit-animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5);
          animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5);
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}