.modal-80w {
  width: 80%;
  max-width: none !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.p-dropdown-panel {
  z-index: 1050 !important;
}