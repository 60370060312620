.UserList {
  /* color: $color-white; */
}
.UserList ul {
  padding-left: 17px;
}
.UserList ul li {
  margin-bottom: 0.5em;
}
.UserList a {
  /* color: $color-white; */
}