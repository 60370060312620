/* eslint font-family-no-missing-generic-family-keyword: warn */
/* @import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css"); */
/*
 * MODIFIED
 *    $Date: Mon Dec 16 09:25:55 PST 2019$
 */
@font-face {
  font-family: "Glyphicons Halflings";
  src: url("http://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.eot");
  src: url("http://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("http://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.woff") format("woff"), url("http://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("http://netdna.bootstrapcdn.com/bootstrap/3.0.0/fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular") format("svg");
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings", serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

.glyphicon-print::before {
  content: "\E045";
}

/*
body {
  background-color: $color-white;
  font-family: 'Helvetica-Light', Helvetica, Arial, sans-serif;
}
*/
.App {
  color: #333;
}
.App .header {
  overflow: auto;
  padding: 15px;
}
.App .header img {
  float: left;
  height: 70px;
  margin-right: 1em;
}
.App hr {
  margin-bottom: 0;
  margin-top: 0;
  opacity: 0.15;
}