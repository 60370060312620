
.Point__item--ARVjK {
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 0.5em 0;
}

.Point__number--2kaRw {
  position: relative;
  display: inline-block;
  top: 8px / 2;
  height: 30px;
  width: 30px;
  border-radius: 30px;
  line-height: 30px;
  text-align: center;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #7d7e7d), to(#565656));
  background: linear-gradient(to bottom, #7d7e7d 20%, #565656 100%);
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.Point__itemFlatNumber--2igsX {
  background: #7d7e7d;
}

.Point__activeItemNumber--1lZSR {
  background: -webkit-gradient(linear, left top, left bottom, from(#f9c667), to(#f79621));
  background: linear-gradient(to bottom, #f9c667 0%, #f79621 100%);
}

.Point__activeFlatItemNumber--16z6E {
  background: #F8A50A;
}

.Point__doneItemNumber--1tRGA {
  background: -webkit-gradient(linear, left top, left bottom, from(#a4b357), to(#75890c));
  background: linear-gradient(to bottom, #a4b357 0%, #75890c 100%);
}

.Point__doneFlatItemNumber--1HQx7 {
  background: #81941F;
}

.Point__arrow--3g40S {
  margin-bottom: -5px;
}

.Point__text--SibMk {
  color: #706D6D;
  font-weight: 700;
  font-size: 0.7em;
  padding: 0.5em;
}

.Point__status--3gkQM {
  color: #706D6D;
  font-weight: 700;
  font-size: 0.8em;
  padding: 0.1em;
}

.Point__outline--2Xrhm {
  -webkit-box-shadow: inset 0 1px 0 0 rgba(200, 200, 200, 0.2), inset 0 2px 0 0 rgba(200, 200, 200, 0.1);
          box-shadow: inset 0 1px 0 0 rgba(200, 200, 200, 0.2), inset 0 2px 0 0 rgba(200, 200, 200, 0.1);
  display: block;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 10px;
  height: 9px;
  border-top: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom: 1px solid rgba(100, 100, 100, 0.2);
}

.Point__numberOutline--1uX_3 {
  position: relative;
  z-index: 2;
  -webkit-box-shadow: inset 0 1px 0 0 rgba(200, 200, 200, 0.3), inset 0 2px 0 0 rgba(200, 200, 200, 0.2), inset 0 -1px 0 0 rgba(200, 200, 200, 0.3), inset 0 -2px 0 0 rgba(200, 200, 200, 0.2);
          box-shadow: inset 0 1px 0 0 rgba(200, 200, 200, 0.3), inset 0 2px 0 0 rgba(200, 200, 200, 0.2), inset 0 -1px 0 0 rgba(200, 200, 200, 0.3), inset 0 -2px 0 0 rgba(200, 200, 200, 0.2);
  background: #fff;
  display: inline-block;
  text-align: center;
  height: 30px + 8px;
  width: 30px + 8px;
  left: auto;
  right: auto;
  margin-left: -(8px / 2);
  margin-top: -(8px / 2);
  border-radius: 30px + 8px;
}

.Point__outlineFirst--15hVO {
  border-left: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-left-radius: 9px;
  border-top-left-radius: 9px;
}

.Point__outlineLast--1BspQ {
  border-right: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-right-radius: 9px;
  border-top-right-radius: 9px;
}
