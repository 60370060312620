.pcr-date-input {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.pcr-date-input-calendar {
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
  /*top: 38vh !important;
  left: auto !important;
  position: absolute!important;*/
}

.pcr-date-input-label {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  margin-left: 15px;
}

/*.summary-table td, .summary-table th {
    width: 4.8%;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
}
*/