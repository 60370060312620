.Basic__item--3h9Rv {
  display: inline-block;
  margin: 0.5em;
  height: 30px;
  line-height: 30px;
  border-radius: 0.4em;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #7d7e7d), to(#565656));
  background: linear-gradient(to bottom, #7d7e7d 20%, #565656 100%);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0;
  white-space: nowrap;
}

.Basic__itemFlat--sHLf3 {
  background: #7d7e7d;
}

.Basic__activeItem--nBb5A {
  background: -webkit-gradient(linear, left top, left bottom, from(#f9c667), to(#f79621));
  background: linear-gradient(to bottom, #f9c667 0%, #f79621 100%)
}

.Basic__activeFlatItem--2g3pe {
  background: #F8A50A;
}

.Basic__doneItem--2NDOy {
  background: -webkit-gradient(linear, left top, left bottom, from(#a4b357), to(#75890c));
  background: linear-gradient(to bottom, #a4b357 0%, #75890c 100%)
}

.Basic__doneFlatItem--1HpPv {
  background: #81941F;
}

.Basic__arrow--fE0k6 {
  display: inline-block;
  position: relative;
  margin-bottom: -18px;
  bottom: 9px;
}

.Basic__number--zuepG {
  display: inline-block;
  padding: 0 0.5em 0 0.85em;
}

.Basic__text--EOhZ- {
  display: inline-block;
  font-size: 0.8em;
  padding: 0 1em;
}

