.cu-title {
  /*
  background: #4f565d;
  color: white;
  */
}

.cu-title-item-container {
  margin: 5px 10px 5px 5px;
}

.cu-status-select-fg {
  margin-bottom: 0 !important;
  margin-right: 5px;
}

.cu-status-select {
  margin-right: 5px;
}