.split {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: -webkit-box-flex 0.7s ease-out;
  transition: -webkit-box-flex 0.7s ease-out;
  transition: flex 0.7s ease-out;
  transition: flex 0.7s ease-out, -webkit-box-flex 0.7s ease-out, -ms-flex 0.7s ease-out;
}

.split,
.gutter.gutter-horizontal {
  float: left;
  overflow-x: scroll;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
}

.gutter.gutter-horizontal {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
}