/*
 * MODIFIED
 *    $Date: Fri Feb 28 16:59:14 IST 2020$
 */

.Search__search-table--3U2T1 {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin: 20px 20px 0 20px; /* no bottom margin */
}

.Search__search-table--3U2T1 span {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
}

.Search__search-header--3GMDf, .Search__search-row--32B7m {
  display: table-row;
  border: 1px solid black;
  cursor: default;
}

.Search__search-row--32B7m.Search__nth--1XcCZ {
  background: repeating-linear-gradient(
    45deg,
    #606dbc,
    #606dbc 10px,
    #465298 10px,
    #465298 20px
  );
  color: white;
}

.Search__search-row--32B7m:hover {
  background: #aaa;
}

.Search__search-collection--1RBLE {
  font-size: x-small;
  width: 50px;
}

.Search__search-collection--1RBLE.Search__wide--3MAN4 {
  min-width: 150px;
}

/* define height and width of scrollable area. Add 16px to width for scrollbar          */
div.Search__tableContainer--1SRKF {
	clear: both;
	height: calc(100vh - 150px); /* 500px */
	overflow-y: auto;
}

/*
div.tableContainer {
	overflow-y: hidden;
} Reset overflow value to hidden for all non-IE browsers. */

/*
 * set table header to a fixed position. WinIE 6.x only
 * In WinIE 6.x, any element with a position property set to relative
 * and is a child of an element that has an overflow property set,
 * the relative value translates into fixed.
 * Ex: parent element DIV with a class of tableContainer has an
 * overflow property set to auto
 */
.Search__search-table--3U2T1 li.Search__search-row--32B7m {
	position: relative
}

.Search__search-table--3U2T1 .Search__scrollContent--3K9iE {
  height: calc(100vh - 210px) /*432px*/;
  overflow-y: auto;
  width: 100%
}

.Search__center-wrapper--1WSSD {
  text-align: 'center'
}

.Search__center-wrapper--1WSSD input {
  outline: none;
  width: 250px;
}

.Search__center-wrapper--1WSSD div,
.Search__center-wrapper--1WSSD input
{
  display: inline-block;
}
