
.Circle__item--1H91L {
  display: inline-block;
  margin: 0.5em;
}

.Circle__number--28mlv {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  line-height: 45px;
  text-align: center;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #7d7e7d), to(#565656));
  background: linear-gradient(to bottom, #7d7e7d 20%, #565656 100%);
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 0;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  margin-right: 0.5em;
}

.Circle__itemFlatNumber--2TS8H {
  background: #7d7e7d;
}

.Circle__activeItemNumber--wpxli {
  background: -webkit-gradient(linear, left top, left bottom, from(#f9c667), to(#f79621));
  background: linear-gradient(to bottom, #f9c667 0%, #f79621 100%);
}

.Circle__activeFlatItemNumber--3q5yJ {
  background: #F8A50A;
}

.Circle__doneItemNumber--tkrFk {
  background: -webkit-gradient(linear, left top, left bottom, from(#a4b357), to(#75890c));
  background: linear-gradient(to bottom, #a4b357 0%, #75890c 100%);
}

.Circle__doneFlatItemNumber--1BBtP {
  background: #81941F;
}

.Circle__arrow--1S0Fy {
  margin-bottom: -5px
}

.Circle__text--36SDh {
  color: #706D6D;
  font-weight: 300;
  font-size: 0.8em;
  padding: 0.1em;
}

.Circle__status--XZ2QX {
  color: #706D6D;
  font-weight: 700;
  font-size: 0.8em;
  padding: 0.1em;
}

.Circle__labels--RyJPK {
  display: inline-block;
}
