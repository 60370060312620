/*
 * MODIFIED
 *    $Date: Wed Dec 25 14:21:52 PST 2019$
 */
.delete {
  position: relative;
  top: -3px;
  z-index: 10;
}

.dropzone-footer {
  position: relative;
  z-index: 10;
  left: -110px;
  text-align: left;
  font-size: xx-small;
  top: 60px;
  /*
    position: relative;
    top: 60px;
    z-index: 10;
    left: -110px;
    text-align: left;
    font-size: xx-small; */
}

.dropzone-container {
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: unset;
  margin-right: unset;
  max-width: 800px;
}

/* this white-space directive controls the wrapping of thumbnails
   inside the <aside> tag. Found here
   stackoverflow.com/questions/22061287/word-wrap-not-working-in-li-element
*/
.dropzone-container ul {
  white-space: normal;
}

.dropzone-thumb.red {
  border-color: red !important;
}

.dropzone-thumb.green {
  border-color: green !important;
}

.dropzone-uploaded-outter {
  margin: 10px 0 10px 0;
  border: 1px solid #ccc;
  padding: 5px;
  background: white;
}

.uploaded-thumb-container {
  padding: 5px;
  margin-right: 10px;
}

.uploaded-thumb-container-blue {
  border: 2px solid #70abea;
}

.thumb-images {
  width: 80px;
  border-radius: 2px;
  border: 1px solid #eaeaea;
}

.thumb-images-footer {
  width: 80px;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  font-size: 9px;
}

.dd-thumbs-container {
  border-color: #aaa;
  border-style: dashed;
  border-radius: 2px;
  border-width: 2px;
  padding-top: 5px;
}

.dd-thumbs-content {
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
  padding: 10px;
}

.tooltip {
  background: none !important;
  border: 0 !important;
}

.dd-images-row {
  /*
  border-top: 2px solid #b3c2c5;
  border-right: 2px solid #b3c2c5; 
  border-left: 5px solid #b3c2c5;
  */
}

.dd-images-cell {
  /*padding: 0px 0px 5px 0px !important;*/
}

.dd-images-cell-inner {
  /*border: 2px solid #b3c2c5; */
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*flex-wrap: wrap;*/
  width: 765px;
  overflow-x: scroll;
}