
  svg.SvgOffice__svgmouse--NaBVD {
    background-color: #fff;
    mix-blend-mode: xor;
    background-blend-mode: difference, normal;
    margin: 10px auto 0 0;
    width: 200px;
  }

  .SvgOffice__hover_group--3b4Wr:hover {
      opacity: 0.2;
      fill: red;
      background: red;
  }

  .SvgOffice__cls1--Fc-Ua,.SvgOffice__cls3--2NdoR {
    fill:#fff;
    stroke-width:0.27px;
  }

  .SvgOffice__cls1--Fc-Ua,.SvgOffice__cls10--1fdzD,.SvgOffice__cls11--36mbV,.SvgOffice__cls12--3iMWv,.SvgOffice__cls2--2fJpf,.SvgOffice__cls3--2NdoR,.SvgOffice__cls8--1w41B
    { stroke:#000; }

  .SvgOffice__cls1--Fc-Ua,.SvgOffice__cls11--36mbV,.SvgOffice__cls12--3iMWv,.SvgOffice__cls2--2fJpf,.SvgOffice__cls3--2NdoR,.SvgOffice__cls8--1w41B
    { stroke-miterlimit:3; }

  .SvgOffice__cls12--3iMWv,.SvgOffice__cls2--2fJpf
    { fill:#bbb; }

  .SvgOffice__cls2--2fJpf
    { stroke-width:0.15px; }

  .SvgOffice__cls3--2NdoR
    { stroke-dasharray:0.72; }

  .SvgOffice__cls4--39KRg
    { fill:url('#myGradient'); }

  .SvgOffice__cls5--2x3Mm
    { fill:url(#linear-gradient); }

  .SvgOffice__cls6--aipMY
    { fill:url(#linear-gradient-2); }

  .SvgOffice__cls7--3k7sD
    { fill:url(#linear-gradient-3); }

  .SvgOffice__cls11--36mbV,.SvgOffice__cls8--1w41B
    { fill:#b5b5b5; }

  .SvgOffice__cls12--3iMWv,.SvgOffice__cls8--1w41B
    { stroke-width:0.11px; }

  .SvgOffice__cls9--dJQys
    { fill:url(#radial-gradient-2); }

  .SvgOffice__cls10--1fdzD {
    fill:none;
    stroke-miterlimit:2;
    stroke-width:0.18px;
  }

  .SvgOffice__cls11--36mbV
    { stroke-width:0.04px; }

    .SvgOffice__gutter--1pd2J.SvgOffice__gutter-vertical--2P5Ib {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
}

    .SvgOffice__gutter--1pd2J.SvgOffice__gutter-horizontal--1ZJyb {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
}

.SvgOffice__split--3nkua {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition:width 0.7s ease-out;
    transition:width 0.7s ease-out;
}

.SvgOffice__split-flex--1IaXF {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: inherit;
    -webkit-transition:width 0.7s ease-out;
    transition:width 0.7s ease-out;
}

.SvgOffice__form-inline--1QLPW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Add some margins for each label */
.SvgOffice__form-inline--1QLPW label {
  margin: 2px 5px 2px 0;
  font-size: smaller;
}

/* Style the input fields */
.SvgOffice__form-inline--1QLPW input {
  vertical-align: middle;
  margin: 2px 2px 0 2px;
  padding: 10px;
  width: 50px;
  background-color: #fff;
  border: 1px solid #ddd;
}

/* Style the submit button */
.SvgOffice__form-inline--1QLPW button {
  padding: 10px 20px;
  background-color: dodgerblue;
  border: 1px solid #ddd;
  color: white;
}

.SvgOffice__form-inline--1QLPW button:hover {
  background-color: royalblue;
}

/* Add responsiveness - display the form controls vertically instead
   of horizontally on screens that are less than 800px wide */
@media (max-width: 800px) {
  .SvgOffice__form-inline--1QLPW input {
    margin: 10px 0;
  }

  .SvgOffice__form-inline--1QLPW {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }
}

.SvgOffice__float-container--3AJyI {
  border: solid 1px #ccc;
  padding: 0 8px;
  position: relative;
}
.SvgOffice__float-container--3AJyI input {
  border: none;
  font-size: 16px;
  margin: 16px 0 10px;
  outline: 0;
}
.SvgOffice__float-container--3AJyI label {
  font-size: 16px;
  position: absolute;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translate(0, 16px) scale(1);
          transform: translate(0, 16px) scale(1);
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}
.SvgOffice__float-container--3AJyI.SvgOffice__active--2SGVJ label {
  -webkit-transform: translate(0, 4px) scale(.75);
          transform: translate(0, 4px) scale(.75);
}

  div[id^='desc_'] {
    margin-bottom: 2px;
    border: 1px;
    border-radius: 4px;
  }

  div[id^='desc_'] input {
    padding-top: 0;
    padding-bottom: 0;
  }

  div[id^='desc_']:hover {
    color: #DDD;
    background: #F00 !important;
  }

  /*
   *
   * justify the tissue input fields in columns, scrollable to the
   * right. Each column takes up 200px and aligns the fields nicele
   * across (this is the reason to use tabular form)
   */
  .SvgOffice__form-table--2iMO9 {
    /*display:block;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    
    font-size: 12px; /*medium;*/ /*;*/
    width: 80%;  /*650px;*/ /* 3-column= 650px, 4-column= 900px  */
  }

  .SvgOffice__form-tr--3IbMa { display:block; }

  .SvgOffice__form-td--2ih9G {
    display:inline-block;
    margin: 30px 0 0 10px;
   }

  .SvgOffice__form-td--2ih9G.SvgOffice__short--3qWgy {
    width:200px;
  }

  .SvgOffice__form-td--2ih9G.SvgOffice__short--3qWgy label {
    line-height: initial;
  }

  /*
   * spin button of the input number field should be wider
   */
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
    -webkit-appearance: inner-spin-button !important;
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
