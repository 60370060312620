/*
 * MODIFIED
 *     $Date: Wed Dec 18 21:47:36 PST 2019$
 */
.Pastel1.q0-7 {
  background-color: rgb(251, 180, 174) !important;
}

.Pastel1.q1-7 {
  background-color: rgb(179, 205, 227) !important;
}

.Pastel1.q2-7 {
  background-color: rgb(204, 235, 197) !important;
}

.Pastel1.q3-7 {
  background-color: rgb(222, 203, 228) !important;
}

.Pastel1.q4-7 {
  background-color: rgb(254, 217, 166) !important;
}

.Pastel1.q5-7 {
  background-color: rgb(255, 255, 204) !important;
}

.Pastel1.q6-7 {
  background-color: rgb(229, 216, 189) !important;
}

.fixed_header tbody {
  /*display:block;*/
  /*overflow:auto;
  height:380px;*/
  width: 100%;
}

tr {
  height: 40px;
}

tr:nth-child(even) {
  /*background-color: #f2f2f2;*/
}

.fixed_header thead tr {
  /*display:block;*/
}

.scrolling-wrapper {
  overflow-x: scroll;
  /*overflow-y: scroll;*/
  white-space: nowrap;
  /*max-height: 500px;*/
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}

.card {
  display: inline-block;
}

/*
.tab-content,
.tab-content > tab-pane:.active {
   height: 85%;
}
*/
.green {
  background: #a1d99b !important;
  color: black;
}

td.red > span {
  opacity: 0.5;
  font-weight: bold;
  color: red;
}

td {
  padding: 2px;
  text-align: center;
}

.data-grid-container .data-grid {
  width: 100%;
}

.data-grid-container .data-grid .cell.scrolly {
  background-color: white;
  background: white;
  text-align: center;
}

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
  display: block;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  /*width: 190px;*/ /*120px;*/
  text-align: center; /*was left*/
}

.data-grid-container .data-grid .cell .value-editor {
  height: inherit;
}

.data-grid-container .data-grid .vert-aligned {
  vertical-align: middle;
}

.header-cell {
  /*width: 140px;*/
  text-align: center;
  border-right: 1px solid #ddd;
}

.scrolly.freeze_horizontal {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 30ms;
          transition-duration: 30ms;
}
.scrolly.freeze_horizontal::before {
  will-change: transform;
}

.scrolly.freeze_horizontal.last::after {
  border-right: 2px solid;
}

.check-err, .check-ok, .check-nop {
  float: right;
  border-radius: 0;
  border-radius: 2px;
  padding: 2px 20px;
  background: #e63946;
  color: #fff;
  cursor: pointer;
  font-size: 9px;
}

.check-nop {
  background: #ccc;
}

.check-ok {
  background: #b2df8a;
}

.ds-grid-col-custom {
  padding: 0 2px 0 2px;
  overflow-wrap: break-word;
  white-space: break-spaces;
  word-break: break-word;
  max-height: 200px;
  /*
  width: 200px;
  padding: 5px;
  font-size: 1rem;
  white-space: break-spaces; 
  word-break: break-word;
  max-height: 80px;
  */
}

.action-cell {
  width: 1.5%;
}